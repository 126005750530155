import componentsCheckoutCartContainer from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/checkout/cartContainer"
import componentsCheckoutFooter from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/checkout/footer"
import componentsCheckoutOrderItem from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/checkout/orderItem"
import componentsCheckoutShippingAndDelivery from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/checkout/shippingAndDelivery"
import componentsCmsProductRecommendations from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/cms/productRecommendations"
import componentsDialogPickupInStore from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/dialog/pickupInStore"
import componentsDialogQuickshop from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/dialog/quickshop"
import componentsDialogSignUp from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/dialog/signUp"
import componentsFormInterests from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/form/interests"
import componentsFormSignUp from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/form/signUp"
import componentsKlarna from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/klarna"
import componentsLoyaltyBenefits from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/components/loyalty/benefits"
import pagesCart from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/pages/cart"
import pagesHelp from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/pages/help"
import pagesLoyaltyProgramme from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/pages/loyaltyProgramme"
import pagesOrderStatus from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/pages/orderStatus"
import pagesPdp from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/pages/pdp"
import pagesRepairService from "/opt/build/repo/brands/thenorthface/regions/emea/config/app/pages/repairService"
    
export default {"components":{"checkout":{"cartContainer":componentsCheckoutCartContainer,"footer":componentsCheckoutFooter,"orderItem":componentsCheckoutOrderItem,"shippingAndDelivery":componentsCheckoutShippingAndDelivery},"cms":{"productRecommendations":componentsCmsProductRecommendations},"dialog":{"pickupInStore":componentsDialogPickupInStore,"quickshop":componentsDialogQuickshop,"signUp":componentsDialogSignUp},"form":{"interests":componentsFormInterests,"signUp":componentsFormSignUp},"klarna":componentsKlarna,"loyalty":{"benefits":componentsLoyaltyBenefits}},"pages":{"cart":pagesCart,"help":pagesHelp,"loyaltyProgramme":pagesLoyaltyProgramme,"orderStatus":pagesOrderStatus,"pdp":pagesPdp,"repairService":pagesRepairService}}
