import type { CartOrderSummaryConfig } from '#types/config/components/cart/orderSummary'
import type { RecursivePartial } from '#types/utils'

export default {
  loyalty: {
    showEachRewardInSummary: true,
    showLoyaltyOnEmptyCart: false
  },
  orderSummary: {
    header: 'text-md fw-bold',
    subtotal: 'text-sm',
    total: 'uppercase text-sm fw-bold'
  },
} satisfies RecursivePartial<CartOrderSummaryConfig>
