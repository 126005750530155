import type { CartConfig } from '#types/config/pages/cart'
import type { RecursivePartial } from '#types/utils'

export default {
  coupon: {
    isAccordionOpen: true,
    isAutoPromoCodeEnabled: true,
  },
  cartItem: {
    allowRemovalByDecreasingQty: true,
    allowRemovalUndo: true,
    variants: {
      showLabel: true
    }
  }
} satisfies RecursivePartial<CartConfig>
